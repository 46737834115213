














import { SitePermissionEnum } from "@/includes/types/AddAdmin/types";

import { Vue, Component, Prop } from 'vue-property-decorator';
import { snakeCase } from "lodash";

@Component({})
export default class PermissionsBanner extends Vue {
  @Prop({ type: String, required: true }) permission!: SitePermissionEnum

  get permissionDescription() {
    return this.$t(`empty_permission_${ snakeCase(this.permission) }_title`).toString()
  }
}
